import { useParams } from 'react-router-dom'
import {
  TextContent,
  BreadcrumbGroup,
  Spinner,
  Box,
  Header,
  SpaceBetween,
  Container,
  ColumnLayout,
  ButtonDropdown,
  ButtonDropdownProps,
  Alert,
  Link,
} from '@cloudscape-design/components'
import { Customer, Tenant } from 'twirp/api'
import { useState } from 'react'
import { TCCService } from 'services/api'
import { useQuery } from 'react-query'
import {
  allTenantServicesSuccessfullyConfigured,
  allTenantServicesSuccessfullyDestroyed,
  tenantFailedServiceConfiguration,
} from 'lib/tenant-services'
import { DestroyTenantModal, DestroyTenantModalName } from 'components/DestroyTenantModal'
import { DeleteTenantModal, DeleteTenantModalName } from 'components/DeleteTenantModal'
import { useModalEnabler } from 'components/ModalContext'
import { ExportTenantModal, ExportTenantModalName } from 'components/ExportTenantModal'
import { newAtriumLink, oldAtriumLink } from 'lib/atrium'
import { CopyOnClickText } from 'components/CopyOnClickText'
import { DeployTenantDataModal, DeployTenantDataModalName } from 'components/DeployTenantDataModal'
import { TenantProvisioningLogModal, TenantProvisioningLogModalName } from 'components/TenantProvisioningLogModal'
import { isStgOrPrd } from 'lib/env'

export const TenantPage = () => {
  const { customerId, tenantId } = useParams()

  const [customer, setCustomer] = useState<Customer>()
  const [tenant, setTenant] = useState<Tenant>()

  const { isLoading } = useQuery('getTenant', async () => {
    const tenantData = await TCCService.GetTenant({
      tenantId: tenantId!,
    })
    setTenant(tenantData.tenant)

    const customerData = await TCCService.GetCustomer({
      customerId: customerId!,
    })
    setCustomer(customerData.customer)

    return [tenantData.tenant, customerData.customer]
  })

  const { enableModal } = useModalEnabler()
  const handleActionItemClick = (item: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    switch (item.detail.id) {
      case 'deploy-data': {
        enableModal(DeployTenantDataModalName)
        break
      }
      case 'export': {
        enableModal(ExportTenantModalName)
        break
      }
      case 'destroy': {
        enableModal(DestroyTenantModalName)
        break
      }
      case 'delete': {
        enableModal(DeleteTenantModalName)
        break
      }
      case 'provisioning-log': {
        enableModal(TenantProvisioningLogModalName)
        break
      }
    }
  }

  if (isLoading) {
    return <Spinner></Spinner>
  }

  const canDelete = tenant && allTenantServicesSuccessfullyDestroyed(tenant) && !tenant.archived

  const canDeployData = tenant && !tenant.archived && allTenantServicesSuccessfullyConfigured(tenant)

  return (
    <Box padding="m">
      <SpaceBetween size="m">
        <BreadcrumbGroup
          items={[
            { text: 'Customers', href: '/customers' },
            { text: customer?.name || '', href: `/customers/${customer?.id}` },
            { text: tenant?.name || '', href: '' },
          ]}
          ariaLabel="Breadcrumbs"
        />

        {tenant?.archived && (
          <Alert header="Archived Tenant" type="warning">
            The tenant being viewed is archived and does not contain any data or related resources.
          </Alert>
        )}

        {tenant && tenantFailedServiceConfiguration(tenant) && (
          <Alert header="Tenant Configuration Failed" type="warning">
            One or more services failed to be properly configured from the template upon creation. See the{' '}
            <Link onFollow={() => enableModal(TenantProvisioningLogModalName)}>Provisioning Log</Link> for more details.
          </Alert>
        )}

        <SpaceBetween size="m">
          <Header
            variant="h1"
            actions={
              <ButtonDropdown
                items={[
                  {
                    text: 'Deploy Data',
                    id: 'deploy-data',
                    disabled: !canDeployData,
                    iconName: 'insert-row',
                  },
                  {
                    text: 'Export',
                    id: 'export',
                    disabled: false,
                    iconName: 'file',
                  },
                  {
                    text: 'Destroy',
                    id: 'destroy',
                    disabled: isStgOrPrd(),
                    iconName: 'delete-marker',
                  },
                  {
                    text: 'Delete',
                    id: 'delete',
                    disabled: !canDelete || isStgOrPrd(),
                    iconName: 'remove',
                  },
                  {
                    text: 'Provisioning Log',
                    id: 'provisioning-log',
                    disabled: false,
                    iconName: 'folder',
                  },
                ]}
                onItemClick={handleActionItemClick}
              >
                Actions
              </ButtonDropdown>
            }
          >
            {tenant?.name}
          </Header>
          <Container header={<Header>Overview</Header>}>
            <ColumnLayout borders="vertical" columns={3}>
              <Box padding="s">
                <SpaceBetween direction="vertical" size="s">
                  <TextContent>
                    <h5>Tenant ID</h5>
                    <CopyOnClickText value={tenant?.id || ''} />
                  </TextContent>
                  <TextContent>
                    <h5>Links</h5>
                    <SpaceBetween size="s" direction="horizontal">
                      <Link external href={oldAtriumLink()}>
                        Atrium (old)
                      </Link>
                      <TextContent>(organization: {tenant?.subdomain})</TextContent>
                    </SpaceBetween>

                    <Link external href={newAtriumLink(tenant?.subdomain || '')}>
                      Atrium (new)
                    </Link>

                    <Box display={tenant?.createdFromTemplateId ? undefined : 'none'}>
                      <Link href={'/templates/' + tenant?.createdFromTemplateId}>Source Template</Link>
                    </Box>
                  </TextContent>
                </SpaceBetween>
              </Box>
              <Box padding="s">
                <SpaceBetween direction="vertical" size="s">
                  <TextContent>
                    <h5>Auth0 Organization ID</h5>
                    <CopyOnClickText value={tenant?.auth0OrgId} />
                  </TextContent>
                  <TextContent>
                    <h5>Database Cluster</h5>
                    <p>{tenant?.dbCluster}</p>
                  </TextContent>
                </SpaceBetween>
              </Box>
              <Box padding="s">
                <TextContent>
                  <h5>Creation Time</h5>
                  <p>
                    {tenant?.createdAt?.toLocaleDateString()} {tenant?.createdAt?.toLocaleTimeString()}
                  </p>
                </TextContent>
              </Box>
            </ColumnLayout>
          </Container>
        </SpaceBetween>
        <DestroyTenantModal tenant={tenant!} />
        <DeleteTenantModal customer={customer!} tenant={tenant!} />
        <ExportTenantModal tenant={tenant!} />
        <DeployTenantDataModal tenantId={tenant!.id} />
        <TenantProvisioningLogModal tenant={tenant!} />
      </SpaceBetween>
    </Box>
  )
}
