import { useEffect, useState } from 'react'
import { axiosRPC } from 'services/api'
import { TendoCloudConfiguratorServiceClientJSON } from 'twirp/api.twirp-client'
import * as Case from 'case'
import { ObjectData, ObjectDataDeployment, Template } from 'twirp/api'
import { Buffer } from 'buffer'

export const EMPTY_TEMPLATE_ID = 'fa6e5a8d-9087-4cd3-80e4-ddb8bc7c2524'

export interface RenderedTemplate {
  id: string
  name: string
  description: string
  services: {
    [key: string]: unknown
  }
}

export const fetchTemplate = async (id: string): Promise<RenderedTemplate> => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  const resp = await client.GetRenderedTemplate({
    templateId: id,
  })

  const d = JSON.parse(new TextDecoder().decode(resp.templateData))
  const tmpl: RenderedTemplate = {
    id: d.id,
    name: d.name,
    description: d.description,
    services: {},
  }

  Object.keys(d.services).forEach((s) => {
    const actions = d.services[s]

    actions.forEach((a: { payload: string }) => {
      const decodedPayload = JSON.parse(new TextDecoder().decode(Buffer.from(a.payload, 'base64')))
      a.payload = decodedPayload
    })
    tmpl.services[s] = actions
  })

  return tmpl
}

export const useTemplate = (id: string) => {
  const [renderedTemplate, setRenderedTemplate] = useState<RenderedTemplate | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  useEffect(() => {
    setLoading(true)
    setError(undefined)

    const fetchData = async () => {
      const tmpl = await fetchTemplate(id)
      setRenderedTemplate(tmpl)
    }

    fetchData().catch((e) => setError(e as Error))
    setLoading(false)
  }, [id])

  return {
    renderedTemplate,
    loading,
    error,
  }
}

export const useObjectDataDeployments = (tenantId: string) => {
  const [objectDataDeployments, setObjectDataDeployments] = useState<ObjectDataDeployment[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  useEffect(() => {
    setLoading(true)
    setError(undefined)

    const fetchData = async () => {
      const objDataDeployments = await fetchObjectDataDeployments(tenantId)
      setObjectDataDeployments(objDataDeployments)
    }

    fetchData().catch((e) => setError(e as Error))
    setLoading(false)
  }, [tenantId])

  return {
    objectDataDeployments,
    loading,
    error,
  }
}

export const submitTemplate = async (f: File): Promise<string> => {
  const b = await f.arrayBuffer()
  const d = JSON.parse(new TextDecoder().decode(b))

  const upload = {
    name: d.name,
    description: d.description,
    services: {} as {
      [key: string]: unknown
    },
  }

  Object.keys(d.services).forEach((s) => {
    const actions: { name: string; payload: unknown }[] = d.services[s]

    upload.services[s] = actions.map((a: { name: string; payload: unknown }) => {
      const jsonStrPayload = JSON.stringify(a.payload)
      const base64Payload = Buffer.from(jsonStrPayload).toString('base64')

      return {
        name: a.name,
        payload: base64Payload,
      }
    })
  })

  const templateData = new TextEncoder().encode(JSON.stringify(upload))

  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  const resp = await client.UploadTemplate({
    templateData: templateData,
  })

  return resp.templateId
}

export const submitObjectData = async (f: File, templateId?: string): Promise<ObjectData[]> => {
  const buf = await f.arrayBuffer()
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  const resp = await client.UploadObjectData({
    templateId: templateId || '',
    data: new Uint8Array(buf),
  })
  return resp.objectData
}

export const deployObjectData = async (tenantId: string, objectDataIds: string[]): Promise<ObjectData[]> => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  const resp = await client.DeployObjectData({
    tenantId: tenantId,
    objectDataIds: objectDataIds,
  })
  return resp.objectDataDeployments
}

export const fetchObjectData = async (templateId: string): Promise<ObjectData[]> => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  const resp = await client.GetObjectData({
    templateId: templateId,
  })
  return resp.objectData
}

export const fetchObjectDataDeployments = async (tenantId: string): Promise<ObjectDataDeployment[]> => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  const resp = await client.GetObjectDataDeployments({
    tenantId: tenantId,
  })
  return resp.objectDataDeployments
}

export const downloadTemplate = async (template: Template) => {
  fetchTemplate(template.id)
    .then((data) => JSON.stringify(data))
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `tcc-template-${Case.kebab(template.name)}.json`)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    })
}

export const downloadObjectDataPackage = async (templateId: string) => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  client
    .GetObjectDataPackage({
      templateId: templateId,
    })
    .then((resp) => resp.downloadUrl)
    .then((downloadUrl) => {
      const link = document.createElement('a')
      link.href = downloadUrl
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    })
}

export const changeGoldenStatus = async (template: Template, enabled: boolean): Promise<boolean> => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  const resp = await client.UpdateTemplateGoldenStatus({
    templateId: template.id,
    golden: enabled,
  })
  return resp.golden
}

export const changeEmrlessStatus = async (template: Template, emrless: boolean): Promise<boolean> => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  const resp = await client.UpdateTemplateEmrlessStatus({
    templateId: template.id,
    emrless: emrless,
    disableTemplateModification: false,
  })
  return resp.emrless
}

export const deleteTemplate = async (template: Template) => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  await client.DeleteTemplate({
    templateId: template.id,
  })
}

export const publishTemplate = async (template: Template) => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  await client.UpdateTemplatePublishedStatus({
    templateId: template.id,
    published: true,
  })
}

export const updateTemplate = async (template: Template) => {
  const client = new TendoCloudConfiguratorServiceClientJSON(axiosRPC)
  await client.UpdateTemplate({
    templateId: template.id,
    name: template.name,
    description: template.description,
  })
}
